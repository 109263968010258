<template lang="pug">
#root
  md-app.app(:md-scrollbar="false")
    md-app-toolbar.flex.bg-white.md-toolbar
      md-button.md-icon-button.mobile(@click="menuVisible = !menuVisible")
        md-icon menu
      img.header-logo(src="@/assets/images/logo-36Kr.png")
      md-tabs.tabs(md-sync-route)
        md-tab(md-label="游戏开发者峰会", to="/", exact)
        md-tab(md-label="手游出海破新界", to="/campaign/tg2021")
        md-tab(md-label="最新产品与趋势", to="/campaign/gml")
        md-tab(md-label="成就全球强品牌", to="/campaign/competitive_brand")
        md-tab(md-label="跨境电商新篇章", to="/campaign/think_of_retail")
      .spacer
      md-button.md-dense.md-raised.md-primary.padding-btn.pc(href="https://services.google.cn/fb/forms/2021cngoglobalmobilegamenewinsights_reportdownload/")
        | 下载《2021 移动游戏出海洞察报告》
      md-button.md-dense.md-raised.md-primary.padding-btn.mobile(href="https://services.google.cn/fb/forms/2021cngoglobalmobilegamenewinsights_reportdownload/")
        | 下载报告
    md-app-drawer(:md-active.sync="menuVisible")
      md-toolbar.md-transparent(md-elevation="0")
        span.md-title
          | 导航
      md-list
        md-list-item(to="/", exact)
          md-icon videogame_asset
          span.md-list-item-text
            | 游戏开发者峰会
        md-list-item(to="/campaign/tg2021")
          md-icon games
          span.md-list-item-text
            | 手游出海破新界
        md-list-item(to="/campaign/gml")
          md-icon trending_up
          span.md-list-item-text
            | 最新产品与趋势
        md-list-item(to="/campaign/competitive_brand")
          md-icon public
          span.md-list-item-text
            | 成就全球强品牌
        md-list-item(to="/campaign/think_of_retail")
          md-icon shopping_cart
          span.md-list-item-text
            | 跨境电商新篇章
    md-app-content.app-container
      .container.cover-container
        img.cover(src="@/assets/images/ggds_covers/0723_5.jpg")
        img.cover-mobile(src="@/assets/images/ggds_covers/0723_5_mobile.jpg")
        p.cover-text
          | 一年一度的谷歌游戏出海峰会 Think Games 将与您分享最新的全球市场洞察、营销广告打法，同时携手多位国内外重磅嘉宾（App Annie, Magic Tavern, Playrix, 三七互娱, IGG, Goodgame Studios, 游族, 莉莉丝, 金山世游, Betta Games）分享实践案例，一起助力您的游戏 “勇破新界，玩转全球”！
      //- .container.cover-container
      //-   video.video-player(controls, ref="live")
      //-     source(src="https://playback.google.36kr.com/ggds/day3/playlist.m3u8", type="application/x-mpegURL")
      .fixed-container
        h2#videos.header-text(ref="videos")
          | 8 月 3 日 - 打破赛道壁垒
          .header-split
        .videos-container
          .videos
            a.video-card(href="/videos/36", target="_blank")
              .content
                h3.title
                  | 勇破新界，玩转全球
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/ggds_avatars/Deng Hui.jpg")
                    .details
                      h3.name
                        | 邓辉
                      p
                        | 谷歌中国大客户部游戏行业副总裁
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/37", target="_blank")
              .content
                h3.title
                  | 2021 市场洞察：融汇万变，跨界破圈
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/ggds_avatars/Zheng Weida.jpg")
                    .details
                      h3.name
                        | 郑伟达
                      p
                        | App Annie 大中华区负责人
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/38", target="_blank")
              .content
                h3.title
                  | 品类融合先行者实践分享
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/ggds_avatars/Zhao Yijiang.jpg")
                    .details
                      h3.name
                        | 赵伊江
                      p
                        | Google Play 中国大陆地区商业拓展负责人
                  .speaker
                    img.avatar(src="@/assets/images/ggds_avatars/Zhao Yongkai.jpg")
                    .details
                      h3.name
                        | 赵永凯
                      p
                        | Magic Tavern 首席执行官
                  .speaker
                    img.avatar(src="@/assets/images/ggds_avatars/Maxim Kirilenko.jpg")
                    .details
                      h3.name
                        | Maxim Kirilenko
                      p
                        | Playrix 首席商务拓展官
                  .speaker
                    img.avatar(src="@/assets/images/ggds_avatars/Peng Mei.jpg")
                    .details
                      h3.name
                        | 彭美
                      p
                        | 三七互娱海外发行负责人
                  .speaker
                    img.avatar(src="@/assets/images/ggds_avatars/Yi Qi.jpg")
                    .details
                      h3.name
                        | 奕其
                      p
                        | 易娱网络 Puzzles &#38; Survival 主策划
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/39", target="_blank")
              .content
                h3.title
                  | 拥抱多元化玩家，深度拓展海外市场
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/ggds_avatars/Tiff Tang.jpg")
                    .details
                      h3.name
                        | Tiff Tang
                      p
                        | Google Play 游戏商业拓展
                  .speaker
                    img.avatar(src="@/assets/images/ggds_avatars/Chloe Wang.jpg")
                    .details
                      h3.name
                        | Chloe Wang
                      p
                        | Google Ads 大客户部游戏客户经理
                  .speaker
                    img.avatar(src="@/assets/images/ggds_avatars/Ye Tao.jpg")
                    .details
                      h3.name
                        | 陶椰
                      p
                        | 谷歌广告变现专家
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/40", target="_blank")
              .content
                h3.title
                  | 游戏全球发行 Soft Launch 全攻略
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/ggds_avatars/Tiff Tang.jpg")
                    .details
                      h3.name
                        | Tiff Tang
                      p
                        | Google Play 游戏商业拓展
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/41", target="_blank")
              .content
                h3.title
                  | IGG 混合变现实践分享
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/ggds_avatars/Bai Yun.jpg")
                    .details
                      h3.name
                        | 白云
                      p
                        | 谷歌大中华区战略合作总监
                  .speaker
                    img.avatar(src="@/assets/images/ggds_avatars/Malen Li.jpg")
                    .details
                      h3.name
                        | 李美仁
                      p
                        | IGG 广告总监
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
        h2.header-text
          | 8 月 4 日 - 挑战增长天花板
          .header-split
        .videos-container
          .videos
            a.video-card(href="/videos/42", target="_blank")
              .content
                h3.title
                  | 谷歌新产品加速增长
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/ggds_avatars/Duke Dukellis.jpg")
                    .details
                      h3.name
                        | Duke Dukellis
                      p
                        | 谷歌变现业务产品总监
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/43", target="_blank")
              .content
                h3.title
                  | 谷歌产品最新使用秘诀
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/ggds_avatars/Li Yi.jpg")
                    .details
                      h3.name
                        | 李怡
                      p
                        | 谷歌移动应用广告专家
                  .speaker
                    img.avatar(src="@/assets/images/ggds_avatars/Cao Dandan.jpg")
                    .details
                      h3.name
                        | 曹丹丹
                      p
                        | 谷歌大客户部游戏行业经理
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/44", target="_blank")
              .content
                h3.title
                  | 市场深度拓展，赢在欧洲
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/ggds_avatars/Shi Yue.jpg")
                    .details
                      h3.name
                        | 石玥
                      p
                        | 谷歌国际增长顾问
                  .speaker
                    img.avatar(src="@/assets/images/ggds_avatars/Caglar Eger.jpg")
                    .details
                      h3.name
                        | Caglar Eger
                      p
                        | Goodgame Studios 商务拓展和企业发展总监
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/45", target="_blank")
              .content
                h3.title
                  | 海外华人：赢在中文游戏出海前线
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/ggds_avatars/Fangyu Xu.jpg")
                    .details
                      h3.name
                        | 许方瑜
                      p
                        | 谷歌大客户部游戏客户经理
                  .speaker
                    img.avatar(src="@/assets/images/ggds_avatars/Ruihua Yao.jpg")
                    .details
                      h3.name
                        | 姚锐华
                      p
                        | 游族海外投放负责人
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/46", target="_blank")
              .content
                h3.title
                  | YouTuber 营销：流量引爆秘籍
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/ggds_avatars/Jieyu Wang.jpg")
                    .details
                      h3.name
                        | 王捷宇
                      p
                        | 谷歌大客户部游戏客户经理
                  .speaker
                    img.avatar(src="@/assets/images/ggds_avatars/Mingtao Hu.jpg")
                    .details
                      h3.name
                        | 胡铭涛
                      p
                        | 莉莉丝英语市场负责人
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/47", target="_blank")
              .content
                h3.title
                  | 破界 H5，开拓新机遇
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/ggds_avatars/Laiming Jiang.jpg")
                    .details
                      h3.name
                        | 蒋莱茗
                      p
                        | 谷歌商业合作部网页及渠道业务总经理
                  .speaker
                    img.avatar(src="@/assets/images/ggds_avatars/Zhen Li.jpg")
                    .details
                      h3.name
                        | 李振
                      p
                        | 金山世游海外商务总监
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
        h2.header-text
          | 8 月 5 日 - 广告技术新突破
          .header-split
        .videos-container
          .videos
            a.video-card(href="/videos/48", target="_blank")
              .content
                h3.title
                  | 构建 BI 系统，实现高效营销
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/ggds_avatars/Chen Dong.jpg")
                    .details
                      h3.name
                        | 陈栋
                      p
                        | 谷歌技术解决方案顾问
                  .speaker
                    img.avatar(src="@/assets/images/ggds_avatars/Liu Hongxi.jpg")
                    .details
                      h3.name
                        | 刘洪曦
                      p
                        | 谷歌技术解决方案顾问
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/49", target="_blank")
              .content
                h3.title
                  | 巧用数据，打造吸量素材
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/ggds_avatars/Tony Ji.jpg")
                    .details
                      h3.name
                        | Tony Ji
                      p
                        | 谷歌移动应用广告创意专家
                  .speaker
                    img.avatar(src="@/assets/images/ggds_avatars/Peter Zhao.jpg")
                    .details
                      h3.name
                        | Peter Zhao
                      p
                        | 谷歌游戏与应用行业分析主管
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/50", target="_blank")
              .content
                h3.title
                  | AdMob bidding 加速 Betta Games 实现营收新突破
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/ggds_avatars/Vincent Tiao.jpg")
                    .details
                      h3.name
                        | Vincent Tiao
                      p
                        | 谷歌战略客户策略顾问
                  .speaker
                    img.avatar(src="@/assets/images/ggds_avatars/Zhang Nan.jpg")
                    .details
                      h3.name
                        | 张楠
                      p
                        | Betta Games 商务合作负责人
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/51", target="_blank")
              .content
                h3.title
                  | 游戏设计到运营，谷歌云 AI 方案全解析
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/ggds_avatars/Shirley Wang.jpg")
                    .details
                      h3.name
                        | Shirley Wang
                      p
                        | 谷歌客户工程师，数据分析/机器学习
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
            a.video-card(href="/videos/52", target="_blank")
              .content
                h3.title
                  | Applied AI SDKs: 用 AI 打动人心, 游戏体验新突破
                .content-details
                  .speaker
                    img.avatar(src="@/assets/images/ggds_avatars/Mingyi Hu.jpg")
                    .details
                      h3.name
                        | 胡鸣一
                      p
                        | 谷歌大中华区重点客户策略顾问
              .btn-play
                md-button.md-icon-button.md-raised.md-primary
                  md-icon play_arrow
      md-divider
      .container
        .flex-section.flex-reverse
          img.section-image(src="@/assets/images/section-cover-download-ggds.jpg")
          .section-content
            h2.section-header.my-4
              | 下载《2021 年移动游戏出海洞察报告》
            .section-description
              | Google x App Annie 联合发布：融汇万变，品类破圈《2021 年移动游戏出海洞察报告》
            md-button.md-dense.md-green.md-raised.lg-btn(@click="openUri('https://services.google.cn/fb/forms/2021cngoglobalmobilegamenewinsights_reportdownload/')")
              | 点击下载
      .container
        .buttons
          md-button.md-dense.md-primary.md-raised.md-btn(@click="openUri('https://services.google.cn/fb/forms/2021thinkgames_leadgen/')")
            | 新客优选计划
          md-button.md-dense.md-primary.md-raised.md-btn(@click="openUri('https://mp.weixin.qq.com/s/fz6E56-nbtEzPyuNpcS-lA')")
            | 加入谷歌团队
      .footer
        p
          | 如果您也希望尝试谷歌广告解决方案拓展您的海外业务，现在即可致电谷歌专家。
          br.mobile
          | 新用户还可领取最高 400 元优惠券，助力轻松开启谷歌广告之旅。
          br
          | 欢迎拨打：400 - 819 - 1244
          br
          | 工作日：9:00 - 18:00
        md-divider
        img.footer-logo(src="@/assets/images/logo-36Kr-grey.png")
  .hidden(
    :class="{ 'share-mobile': isShare }",
    @click="() => { isShare = false; }"
  )
    img.share-img(src="~@/assets/images/share.png")
  .shares
    .share-icon-container(@click="share")
      md-icon.share-icon share
      .hidden(
        :class="{ 'share-pc': isShare }",
        @click="() => { isShare = false; }"
      )
        img(src="~@/assets/images/share-pc.png", width="150px")
    .share-icon-container(@click="like")
      md-icon.share-icon(:class="{ active: isActive }") favorite
</template>

<script>
import hlsjs from "hls.js";

export default {
  name: "ThinkGames2021",
  components: {},
  data() {
    return {
      isActive: false,
      isStar: false,
      isShare: false,
      isVideoToEnd: false,
      menuVisible: false,
    };
  },
  methods: {
    like() {
      this.$pushEvent("like", {});
      this.isActive = !this.isActive;
    },
    star() {
      this.isStar = !this.isStar;
    },
    share() {
      this.$pushEvent("share", {});
      this.isShare = !this.isShare;
    },
    openUri(uri) {
      this.$pushEvent("openUri", { url: uri }).then(() => {
        window.location.assign(uri);
        // const ua = navigator.userAgent.toLowerCase();
        // if (
        //   (ua.indexOf("safari") !== -1 && ua.indexOf("chrome") === -1) ||
        //   ua.indexOf("micromessenger") !== -1
        // ) {
        //   window.location.assign(uri);
        // } else {
        //   window.open(uri, "_blank");
        // }
      });
    },
    unmute() {
      const video = this.$refs.live;
      video.muted = false;
    },
  },
  mounted() {
    this.$pushEvent("visit", {
      route: "home_live",
      source: window.location.search,
    });

    // This is a hack to Chromium bug
    if (window.location.hash.length > 0) {
      const hash = window.location.hash.substring(1);
      document.getElementById(hash).scrollIntoView();
      // hack to Safari bug
      const ua = navigator.userAgent.toLowerCase();
      if (
        (ua.indexOf("safari") !== -1 && ua.indexOf("chrome") === -1) ||
        ua.indexOf("micromessenger") !== -1
      ) {
        window.setTimeout(() => {
          document.getElementById(hash).scrollIntoView();
        }, 100);
      }
    }

    // HLS.js
    const video = this.$refs.live;
    if (hlsjs.isSupported()) {
      const hls = new hlsjs();
      hls.loadSource(
        "https://playback.google.36kr.com/ggds/day3/playlist.m3u8"
      );
      hls.attachMedia(video);
    }
  },
};
</script>

<style lang="stylus" scoped>
img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

h2 {
  text-align: center;
}

.header-split {
  width: 100%;
  height: 3px;
  margin-top: 8px;
  background: #448aff;
}

h3 {
  font-size: 15px;
}

a {
  cursor: pointer;
}

.report-h2 {
  font-size: 19px;
}

.tabs {
  @media screen and (min-width: 1280px) {
    display: block;
  }

  display: none;
}

.md-toolbar {
  min-height: 48px;
}

.md-toolbar .md-tabs {
  margin: 0 !important;
}

.pc {
  @media screen and (min-width: 1280px) {
    display: block;
  }

  display: none;
}

.mobile {
  @media screen and (min-width: 1280px) {
    display: none;
  }

  display: block;
}

.arrow-container {
  @media screen and (min-width: 1280px) {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 50px;
  }

  display: none;
}

.arrow {
  @media screen and (min-width: 1280px) {
    top: 10px;
    z-index: 9;
    display: block;
    cursor: pointer;
  }
}

.arrow-back {
  margin-right: 10px;
}

.arrow-forward {
  margin-left: 10px;
}

.arrow-icon {
  background-color: #cccccc;
  width: 10px;
  height: 10px;
  border-radius: 5px;

  &.active {
    background-color: #000000;
  }
}

.share-img {
  width: 200px;
  position: absolute;
  right: 0;
  top: 0;
}

.hidden {
  display: none;
}

.show-player {
  display: flex;
}

.share-pc {
  @media screen and (min-width: 1024px) {
    display: block;
    position: absolute;
    left: -150px;
    top: -90px;
  }
}

.share-mobile {
  @media screen and (max-width: 1024px) {
    display: block;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.3;
  }
}

.shares {
  position: fixed;
  background-color: #fff;
  right: 0;
  top: 50%;
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-radius: 10px 0 0 10px;

  @media screen and (max-width: 1280px) {
    top: 30%;
  }
}

.share-icon-container {
  margin: 10px;
  cursor: pointer;
}

.share-icon {
  color: #000 !important;
}

.close-icon {
  margin: 0 0 0 auto;
  cursor: pointer;
}

.active {
  color: #ff0000 !important;
}

.star {
  color: #ffcc00 !important;
}

.my-4 {
  margin-bottom: 20px;
}

.my-8 {
  margin-bottom: 40px;
}

.app {
  width: 100%;
  max-height: 100vh;
  position: absolute;
}

.app-container {
  max-width: 100vw;
  max-height: 100vh;
}

.flex {
  display: flex;
}

.spacer {
  flex: 1;
}

.header-logo {
  height: 24px;
}

.padding-btn {
  padding: 5px 10px;
}

.container {
  width: 100%;
  margin: 0 auto;
}

.fixed-container {
  max-width: 1200px;
  margin: 0 auto;
}

.cover-container {
  padding: 10px 0 50px 0;

  @media screen and (min-width: 1280px) {
    padding: 50px 200px;
  }

  background: #fff;
}

.cover {
  display: none;
  width: 100vw;
  margin: auto;

  @media screen and (min-width: 1280px) {
    display: block;
  }
}

.cover-text {
  padding: 0 20px;
  line-height: 1.8;
  text-align: left;

  @media screen and (min-width: 1280px) {
    padding: 0 50px;
    font-size: 20px;
  }
}

.header-text {
  padding: 16px 20px;
  line-height: 1.8;
  text-align: left;

  @media screen and (min-width: 1280px) {
    padding: 16px 50px;
    font-size: 20px;
  }

  margin: 0;
}

.cover-mobile {
  display: block;
  margin: 10px auto 60px auto;

  @media screen and (min-width: 1280px) {
    display: none;
  }
}

.md-content {
  padding: 0;
}

.article-content-title {
  color: rgb(117, 117, 117);
}

.bg-grey {
  background-color: #f2f2f2;
}

.bg-white {
  background-color: #fff;
}

.md-card-media img {
  border-radius: 32px 32px 0 0;
}

.span-btn:hover {
  text-decoration: none;
}

.span-text {
  display: inline-block;
  line-height: 24px;
  vertical-align: middle;
}

.slide-container {
  @media screen and (min-width: 1280px) {
    width: 800px;
  }

  width: 90%;
  margin: 0 auto;
}

.article-card {
  display: flex;
  cursor: pointer;
  margin: 20px 0;
}

.article-image {
  @media screen and (min-width: 1024px) {
    width: 200px;
    height: calc(200px / 4 * 3);
    border-radius: 32px;
    margin: 0 30px 0 0;
  }

  width: 140px;
  height: calc(140px / 4 * 3);
  border-radius: 16px;
  margin: 0 15px 0 0;
}

.article-content {
  flex: 4;
}

.flex-section {
  display: flex;
  padding: 40px 0;

  @media screen and (min-width: 1280px) {
    flex-direction: row;
    padding: 60px 0;
    justify-content: center;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
  }

  flex-direction: column;
}

.section-description {
  margin: 0 20px 10px 20px;
}

.section-image {
  max-width: 90%;

  @media screen and (min-width: 1280px) {
    max-width: 50%;
  }

  width: auto;
  height: auto;
  justify-self: flex-start;
  align-self: flex-start;
  margin: 0 auto;
}

.section-content {
  @media screen and (min-width: 1280px) {
    width: 480px;
    padding: 0 40px;
  }

  padding: 0 10px;
  margin: auto 0;
}

.lg-btn {
  width: 220px;
  margin: 20px auto;
  display: block;
}

.md-btn {
  width: 110px;
  margin: 20px auto;
  display: block;
}

.flex-reverse {
  @media screen and (min-width: 1280px) {
    flex-direction: row-reverse;
  }

  flex-direction: column;
}

.footer {
  background-color: #f5f5f5;
  padding: 20px;

  .footer-logo {
    margin: 20px 0 0 0;
    // margin: 20px auto 0 auto; 如果需要左右居中，使用此行
    height: 20px;
    display: block;
  }
}

.video-card {
  display: flex;
  margin: 16px 5px 16px 20px;
  padding: 10px 5px;

  @media screen and (min-width: 1280px) {
    margin: 16px 50px 16px 50px;
    padding: 10px 5px 10px 80px;
  }

  h2 {
    text-align: left;
    color: #000;
  }

  h3 {
    text-align: left;
    color: #000;
  }

  .title  {
    font-weight: normal;
    font-size: 20px;
    margin: 5px 0 30px 0;
  }

  .name {
    margin: 0;
  }

  .content {
    flex: 1;
    .content-details {
      @media screen and (min-width: 1280px) {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      .speaker {
        display: flex;
        flex: 1;
        padding: 20px 0;
        @media screen and (min-width: 1280px) {
          padding: 0;
        }
        .avatar {
          width: 64px;
          height: 64px;
          border-radius: 32px;
        }
        .details {
          flex: 1;
          margin: 0 20px;
          color: #858585;
        }
      }
    }
  }

  .btn-play {
    width: 64px;
  }
}

.video-card:nth-child(even) {
  background: #ffffff;
}

.video-card:nth-child(odd) {
  background: #f8f9fb;
}

h3.title {
  line-height: 1.4;
}

.video-player {
  width: 100%;
}

.buttons {
  display: flex;
}
</style>
